/* purgecss start ignore */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* Signin */

.Signin {
  height: 100vh;
  margin: 0;
  background-color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.Signin .logo {
  display: block;
  margin: auto;
  width: 40%;
  margin-top: 50px;
}

.Signin h1 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 3em;
}

.Signin h1.collapse {
  margin: 0;
  margin-bottom: 50px;
}

.Signin h2 {
  text-align: center;
  margin-bottom: 6vh;
  font-size: 2.5em;
}

.Signin h3 {
  font-weight: normal;
  font-size: 1em;
  color: red;
  text-align: center;
}

.Signin p {
  text-align: center;
}

.Signin .wait {
  text-align: center;
  margin: 135px 0;
}

.Signin .promote {
  font-size: 1.1em;
  width: 90%;
  margin: auto;
}

.Signin .info {
  font-size: 0.8em;
  color: #888;
}

.Signin .download {
  width: 60%;
  margin: auto;
  display: block;
  margin-top: 60px;
  font-size: 1.3em;
  font-weight: bolder;
}

.Signin .nothanks {
  width: 60%;
  margin: auto;
  display: block;
  text-align: center;
  font-size: 1.3em;
  margin-top: 30px;
  font-weight: bolder;
  background-color: transparent;
}

.Signin form {
  width: 80%;
  margin: auto;
  color: #898989;
}

.Signin form .row {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
}

.Signin form .row.send {
  text-align: center;
}

.Signin form .row textarea {
  width: 100%;
  height: 25vh;
}

.Signin form .row input[type="text"], .Signin form .row input[type="password"] {
  margin-right: 15px;
  height: 40px;
  padding: 5px 15px;
  font-size: 1.2em;
  width: calc(100% - 30px);
  border: none;
  color: #898989;
  background-color: #F4F4F4;
  border-radius: 5px;
}

.Signin form .row input[type="text"]:focus, .Signin form .row input[type="password"]:focus, .Signin form .row select:focus {
  outline: none;
}

.Signin form .visible {
  width: 100%;
  text-align: right;
  /* margin-top: -5px; */
}

.Signin form .visible p {
  margin-top: 2px;
  float: left;
  margin-left: 40%;
}

.Signin form .visible label {
  float: right;
}

.Signin form .row .birthdate {
  float: right;
  margin-left: 3%;
  width: 30%;
}

.Signin form .row .radio {
  float: left;
  margin-bottom: 12px;
  font-size: 1.3em;
  width: 30%;
  margin-right: 3%;
}

.Signin button {
  text-align: center;
  margin-top: 30px;
  height: 40px;
  font-size: 1em;
  width: 100%;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 20px;
}

.Signin form .step1-btn {
  padding: 0;
  width: 100%;
  font-size: 1.2em;
  height: 50px;
  margin-top: 0;
}

.Signin form button:hover {
  cursor: pointer;
}

.Signin form .label {
  float: right;
  width: 100%;
  text-align: left;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.Signin form .row select {
  background-color: white;
  float: right;
  margin-bottom: 20px;
  height: 50px;
  padding: 0 5px;
  font-size: 1.2em;
  width: 100%;
  color: #898989;
  background-color: #F4F4F4;
  border: none;
  border-radius: 5px;
}

.Signin a {
  color: #898989;
  text-decoration: none;
}

.Signin a u {
  text-decoration: underline;
}

.Signin .center {
  text-align: center;
}

.Signin .box-content {
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
}

.Signin p {
  color: #898989;
  text-align: left;
}

.Signin .underline {
  text-decoration: underline;
}

.Signin .success {
  color: green;
  text-align: center;
}

.Signin form .row .stays {
  font-size: 1em;
}

.Signin .stays option {
  font-size: 0.9em;
}

.Signin .switchPage {
  text-align: center;
  margin-top: 30px;
  font-size: 1.1em;
}

/* purgecss end ignore */
/* purgecss start ignore */

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-Regular.65bb0a15.ttf);
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-Light.c41c2502.ttf);

  font-weight: lighter;
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-SemiBold.713ac08d.ttf);

  font-weight: bold;
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-ExtraLightItalic.592f55ff.ttf);

  font-style: italic;
}

html, body {
  -webkit-overflow-scrolling: touch;
}

.App {
  margin: 0;
  padding: 0;
  border: 0;
  color: #3c3c3c;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Montserrat, sans-serif;
}

/* font-family: Roboto, Montserrat, Arial, serif; */

.App .loading {
  margin: auto;
  margin-top: 45vh;
  text-align: center;
  width: 100%;
}

.Wrapper {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 1.2em;
  z-index: 20;
  position: absolute;
  top: 0;
  height: calc(1vh * 90);
  height: calc(var(--vh, 1vh) * 90);
  width: 100%;
  /* z-index: -100; */
  -webkit-overflow-scrolling: touch;
}

.wrapper-content {
  min-height: 80vh;
}

.wrapper-content .header {
  position: fixed;
  color: white;
  width: 100%;
  background-color: #dedede;
  text-align: left;
  height: 140px;
}

.wrapper-content .header h1 {
  margin: 0;
  font-size: 1.8em;
  padding-top: 25px;
  text-align: left;
  margin: 0 50px 0 25%;
}

.wrapper-content .body {
  position: absolute;
  width: 100%;
  background-color: #FBFBFD;
  border-radius: 12px 12px 0 0;
  margin-top: 120px;
  min-height: 90vh;
  padding: 0 20px;
  padding-top: 10px;
}

.wrapper-content h1 {
  font-size: 1.5em;
  text-align: center;
}

.wrapper-content h2 {
  font-size: 1.3em;
}

.wrapper-content button, .contentWrapper button {
  width: 100%;
  border: 1px solid #f6b25f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #f6b25f;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 5%;
  padding-bottom: 5%;
  position: relative;
  cursor: pointer;
  margin: 5vh 0;
}

.contentWrapper button {
  width: 90%;
  margin: 5vh auto;
  display: block;
}

.success {
  text-align: center;
  color: green;
}

.error {
  text-align: center;
  color: red;
}

.center {
  text-align: center;
  margin: 20px 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Reviews .body {
  padding: 0 15%;
  text-align: left;
  background-color: white;
  padding-bottom: 100px;
}

.Reviews .body h1 {
  font-size: 1.6em;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
  margin-top: 75px;
}

.Reviews .body h1.thanks {
  font-size: 1.8em;
  margin: 0 5%;
  margin-top: 50px;
}

.Reviews .body .introduction {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 80px;
}

.Reviews .illustration {
  margin-top: 50px;
  text-align: center;
}

.Reviews .illustration img {
  width: 50%;
}

.Reviews .body h2 {
  color: #A5A5A5;
  font-weight: bold;
  margin-top: 15px;
  font-size: 0.85em;
}

.Reviews .text {
  margin-top: 100px;
}

.Reviews .notation {
  margin-top: 100px;
  text-align: center;
}

.Reviews .notation img {
  width: 10%;
  margin: 0 5px;
  display: inline-block;
}

.Reviews .select {
  width: 100%;
  font-size: 1.1em;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 20px auto;
  box-shadow: 0px 3px 6px #00000029;
  font-weight: bold;
  color: #909090;
}

.Reviews .select.selected {
  background-color: #EDF2FA;
}

.Reviews .error {
  color: #909090;
  margin: auto;
}

.Reviews .error .icon {
  margin-left: 50px;
  float: left;
  border-radius: 100%;
  background-color: #FF6464;
  color: white;
  width: 24px;
  height: 24px;
  font-size: 20px;
  padding: 2px;
  margin-top: 16px;
}

.Reviews .error p {
  float: left;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 15px;
}

.Reviews .buttons {
  text-align: center;
  width: 90%;
  position: fixed;
  top: 75vh;
  left: 5%;
}

.Reviews button {
  font-size: 1em;
  background-color: #cacaca;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 20px 0;
}

.Reviews button.back {
  float: left;
  width: 20%;
  margin: 0;
  margin-left: 10%;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #909090;
}

.Reviews button.back img {
  width: 40%;
}

.Reviews button.continue {
  float: right;
  width: 50%;
  margin: 0;
  margin-right: 10%;
}

.Reviews button.alone-btn {
  width: 70%;
  margin: auto;
}

/* purgecss end ignore */
/*purgecss start ignore
*/

/*.Header {
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 10vh;
	background-color: #dedede;
	margin: 0;
	padding: 0;
	padding-left: 2%;
	display: flex;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.Header-burger {
	width: 10%;
}

.Header-burger img {
	height: 100px;
}

.Header {
	z-index: 20;
	display: block;
	top: 10px;
	position: fixed;
	width: 100%;
}

.Header a, .Header button {
	z-index: 20;
	margin-left: 10px;
}

.Header-title {
	margin: 0;
	padding: 0;
	width: 80%;
}

.Header-title h1 {
	color: white;
	padding-top: 2.5vh;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
}

.Header-title h1 a {
	color: white;
	text-decoration: none;
}
*/

.Menu {
  /* z-index: 10; */
  display: none;
  top: 0;
  position: fixed;
  margin-left: -80%;
  border-right: 1px solid #c6c6c6;
  width: 80%;
  height: 90vh;
  background-color: white;
  -webkit-animation: 0.5s slideIn;
  animation: 0.5s slideIn;
}

.Menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.Menu li {
  height: 6vh;
  width: 60%;
  padding-left: 20%;
  margin-left: 10%;
  background-size: 15%;
  background-repeat: no-repeat;
  background-position: 5% 50%;
}

.Menu .elem {
  margin-top: 10px;
  width: 100%;
}

.Menu li a {
  color: #3c3c3c;
  text-decoration: none;
}

.Menu .Menu-title {
  padding-top: 3.2vh;
  padding-left: 0;
  margin-left: 0;
  height: 19vh;
  width: 100%;
  text-align: center;
  border-top: none;
  font-size: 1.3em;
}

.Menu .Menu-title img {
  width: 40%;
  margin: auto;
}

.Menu li .icon {
  width: 36px;
  float: left;
  margin-left: -50px;
  margin-top: -10px;
}

@-webkit-keyframes slideIn {
  from {
    margin-left: -80%;
  }

  to {
    margin-left: 0;
  }
}

@keyframes slideIn {
  from {
    margin-left: -80%;
  }

  to {
    margin-left: 0;
  }
}

@-webkit-keyframes slideOut {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -80%;
  }
}

@keyframes slideOut {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -80%;
  }
}

/* RESPONSIVE SMARTPHONE */

@media (max-width: 600px) {
  .Header-burger img {
    height: 5vh;
    padding-top: 2.3vh;
    padding-left: 30%;
  }

  .GhostHeader .Header-burger img {
    padding-left: 20px;
  }

  .Header-title h1 {
    font-size: 1.5em;
  }
}

/*purgecss end ignore
*/
/* purgecss start ignore */

.Slider {
  width: 100%;
  margin-bottom: 30px;
  white-space: nowrap;
}

.listElems {
  width: 90%;
  margin: auto;
  /* overflow-x: hidden; */
}

.Slider .element {
  display: inline-block;
  width: 120px;
  margin-right: 10px;
  vertical-align: top;
}

.Slider .element div {
  height: 147px;
  padding-top: 3vh;
  background-color: #c6c6c6;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  white-space: normal;
}

/* .Slider .element p {
	white-space: normal;
	overflow-wrap: break-all;
	padding: 0;
	margin: 0;
	margin-top: 10px;
	text-align: center;
	color: white;
	font-family: Helvetica, Nunito, Arial, sans-serif; */

/* } */

/* .Slider .element img {
	width: 40%;
	display: inline;
} */

/* purgecss end ignore */
/* purgecss start ignore */

.contentWrapper {
  min-height: 80vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 25vh;
  background-color: #FBFBFD;
  border-radius: 1.5rem 1.5rem 0 0;
}

.contentWrapper .link {
  width: 100%;
  border: 1px solid #f6b25f;
  border-radius: 10px;
  color: white;
  background-color: #f6b25f;
  height: 40px;
  font-size: 1.2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.contentWrapper .fullPage {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: -12vh;
}

/* .contentWrapper .page {
	background-color: white;
	border-radius: 12px;
	padding: 15px 25px 0 25px;
	width: calc(100% - 40px);
	margin: 20px;
	font-size: 0.85em;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	min-height: 80px;
} */

.contentWrapper p {
  margin-bottom: 15px;
}

.contentWrapper .page b, .contentWrapper .page strong {
  font-weight: bolder;
}

.contentWrapper ul {
  padding-left: 40px !important;
}

.contentWrapper ul li {
  list-style-type: disc !important;
}

.contentWrapper .page a {
  color: black;
  text-decoration: none;
}

.contentWrapper .page .reveal-on-tap {
  max-height: 0;
  overflow-y: hidden;
  padding-bottom: 0px;
  -webkit-transition: max-height 0.8s ease, padding-bottom 0.8s ease;
  transition: max-height 0.8s ease, padding-bottom 0.8s ease;
}

.photoWrap {
  max-height: 30vh;
  overflow-y: hidden;
  margin-bottom: 5%;
  position: fixed;
}

.photoWrap img {
  width: 100%;
}

/* .contentWrapper .picto {
	width: 28px;
	margin-top: 12px;
	float: left;
} */

/* .contentWrapper .title {
	padding-left: 42px;
	font-weight: bold;
	font-size: 1.15em;
	margin-bottom: 30px;
	padding-top: 14px;
} */

.contentWrapper form {
  width: 70%;
  margin: auto;
}

.contentWrapper form .row {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 5%;
}

.contentWrapper form label {
  clear: both;
  text-transform: uppercase;
  color: #afafaf;
  font-weight: bold;
}

.contentWrapper form input[type="text"] {
  width: 50%;
  float: right;
  border: 1px solid #e4e9e9;
  height: 30px;
  border-radius: 5px;
}

.contentWrapper form select {
  width: 30%;
  height: 30px;
  border-radius: 5px;
  float: right;
  background-color: white;
  border: 1px solid #e4e9e9;
}

.contentWrapper form button {
  width: 100%;
  border: 1px solid #f6b25f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #f6b25f;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.contentWrapper .check {
  padding-top: 20vh;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
}

.contentWrapper .check img {
  width: 50%;
  padding-bottom: 15%;
}

/* .content {
	width: 90%;
	min-height: 105px;
	margin: 20px auto;
	color: white;
	border-radius: 10px;
	background-color: #c6c6c6;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.content img {
	width: 15%;
	float: left;
	margin-left: 10%;
	margin-top: 7%;
} */

/* .content p {
	margin-left: 35%;
	font-size: 1.05em;
	font-weight: bold;
	text-transform: uppercase;
	padding: 20px 10px 20px 0;
} */

.contentWrapper a {
  text-decoration: none;
}

.content.first {
  background-color: #fbdfb6;
}

.content.second {
  background-color: #f9c981;
}

.content.third {
  background-color: #f6b95c;
}

.content.fourth {
  background-color: #f5a938;
}

.content.fifth {
  background-color: #f3960c;
}

/* purgecss end ignore */
/* purgecss start ignore */

.askNotification
{
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 50px auto 50px auto;
  width: calc(100% - 30px);
  padding: 15px;
  background-color: white;
}

.askNotification .promote {
  margin-left: 15%;
  color: black;
  font-size: 0.8em;
}

.askNotification .promote strong {
  font-size: 1.3em;
}

.custom-checkbox[type="checkbox"]:not(:checked), 
.custom-checkbox[type="checkbox"]:checked {
  /* Cache la checkbox sans
	   la désactiver pour les
	   lecteurs d'écran */
  position: absolute;
  left: 0;
  opacity: 0.01;
}

/* on prépare le label */

.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label {
  float: left;
  position: relative; /* permet de positionner les pseudo-éléments */
  padding-left: 2.3em; /* fait un peu d'espace pour notre case à venir */
  font-size: 1.05em; /* Label un peu plus gros */
  line-height: 1.7;
  cursor: pointer;    /* affiche un curseur adapté */
}

/* Aspect de la case */

.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:before,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #888;
  background-color: #FAFAFA;
  border-radius: .2em;
  box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
  -webkit-transition: all .275s;
  transition: all .275s;
}

/* Aspect de la coche */

.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:after,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:after {
  content: '\2713';
  position: absolute;
  top: .5em;
  left: .1em;
  font-size: 1.6em;
  color: #000;
  line-height: 0;
  -webkit-transition: all .2s;
  transition: all .2s; /* Petite transition */
}

/* Aspect non cochée */

.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
  transform: scale(0) rotate(45deg);
}

/* Aspect cochée */

.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

/* Disabled checkbox */

.custom-checkbox[type="checkbox"]:disabled:not(:checked) + .custom-checkbox-label:before,
.custom-checkbox[type="checkbox"]:disabled:checked + .custom-checkbox-label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9;
}

/* Disabled checked */

.custom-checkbox[type="checkbox"]:disabled:checked + .custom-checkbox-label:after {
  color: #777;
}

.custom-checkbox[type="checkbox"]:disabled + .custom-checkbox-label {
  color: #aaa;
}

/* purgecss end ignore */
/* purgecss start ignore */

.Notifications {
  min-height: 80vh;
}

.Notifications .header {
  position: fixed;
  color: white;
  width: 100%;
  background-color: #dedede;
  text-align: left;
  height: 140px;
}

.Notifications .header h1 {
  margin: 0;
  font-size: 1.8em;
  padding-top: 25px;
  text-align: left;
  margin: 0 50px 0 25%;
}

.Notifications .body {
  position: absolute;
  width: 100%;
  background-color: white;
  margin-top: 120px;
  min-height: 90vh;
}

.notification {
  width: 100%;
  min-height: 7vh;
  font-size: 0.7em;
  padding: 10px 40px;
  border-bottom: 1px solid #DADADA;
}

.notification.unread {
  background-color: #EDF2FA;
}

.notification .title {
  font-weight: bold;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.notification .text {
  padding: 0;
  margin: 0;
}

.notification .time {
  color: #6a6a6a;
  padding: 0;
  margin: 0;
}

/* purgecss end ignore */
/* purgecss start ignore */

/* AnimationMonth */

.AnimationMonth {
  width: 100%;
  background-color: #FBFBFD;
  min-height: 90vh;
  padding-bottom: 20px;
}

.AnimationMonth .header {
  position: fixed;
  color: white;
  width: 100%;
  background-color: #dedede;
  text-align: left;
  height: 140px;
}

/* .AnimationMonth .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 25%;
	padding-top: 25px;
} */

.AnimationMonth .body {
  position: absolute;
  width: 100%;
  background-color: #FBFBFD;
  border-radius: 12px 12px 0 0;
  margin-top: 120px;
  min-height: 90vh;
}

.AnimationMonth .month {
  text-transform: uppercase;
  font-size: 1em;
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.AnimationMonth .month img {
  width: 20px;
  margin-top: 5px;
}

.AnimationMonth .month p {
  margin-top: 40px;
  float: left;
  font-size: 1em;
  font-weight: bold;
}

.AnimationMonth .month .arrow-left, .AnimationMonth .month .arrow-right {
  width: 20%;
}

.AnimationMonth .month .arrow-right {
  float: right;
  text-align: right;
}

.AnimationMonth .month .text {
  text-align: center;
  width: 60%;
  margin-bottom: 20px;
}

.AnimationMonth .calendar {
  clear: both;
  background-color: white;
  color: black;
  border-radius: 10px;
  width: calc(100% - 30px);
  margin: auto;
  padding: 10px 10px 30px 10px;
}

.AnimationMonth .weekDays {
  width: 100%;
  margin: auto;
  color: #C3C3C3;
  border-bottom: 1px solid #E3E3E3;
}

.AnimationMonth .weekDays .name {
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  width: 14.28571%;
}

.AnimationMonth .monthTable {
  width: 100%;
  margin-top: 20px;
}

.AnimationMonth .monthTable .dayRow {
  width: 100%;
}

.AnimationMonth .monthTable .dayRow .day {
  vertical-align: top;
  display: inline-block;
  width: 11%;
  margin-left: 3.83333%;
  height: 5.5vh;
}

.AnimationMonth .monthTable .dayRow .day.highlight {
  /* background-color: #FFB400; */
  background-color: #DADADA;
  border-radius: 10px;
}

.AnimationMonth .monthTable .dayRow .day.first {
  margin-left: 0;
}

.AnimationMonth .monthTable .dayRow .day .num {
  /*color: #3c3c3c;*/
  color: #676565;
  padding: 0;
  margin: 0;
  padding-top: 12px;
  /* margin-top: 2%; */
  /* margin-right: 10%; */
  /* font-weight: bold; */
  text-align: center;
  font-size: 0.9em;
}

.AnimationMonth .monthTable .dayRow .day .num.gray {
  color: #C3C3C3;
}

.AnimationMonth .monthTable .dayRow .day.highlight .num {
  color: #676565;
}

.AnimationMonth .monthTable .dayRow .day .pastille {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  margin-top: 35%;
  margin-left: 7%;
  display: inline-block;
}

.AnimationMonth .monthTable .dayRow .day .pastille.first {
  background-color: #f29100;
}

.AnimationMonth .monthTable .dayRow .day .pastille.second {
  background-color: #71beb9;
}

.AnimationMonth .monthTable .dayRow .day .pastille.third {
  background-color: #9c9c9b;
}

.AnimationMonth .indicators {
  margin-top: 15%;
  margin-left: 5%;
  font-size: 0.9em;
}

.AnimationMonth .indicators .pastille, .AnimationDay .indicators .pastille {
  height: 13px;
  width: 13px;
  background-color: black;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  margin-right: 5%;
  display: inline-block;
}

/* AnimationDay */

.AnimationDay {
  width: 100%;
}

.AnimationDay .header {
  color: white;
  width: 100%;
  background-color: #dedede;
  text-align: left;
  min-height: 140px;
  padding-bottom: 5px;
}

/* .AnimationDay .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 25%;
	padding-top: 30px;
} */

.AnimationDay .days {
  width: 80%;
  margin: auto;
}

.AnimationDay .days .day {
  color: white;
  margin-top: 20px;
  float: left;
  width: 15%;
  margin-left: 5%;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  /* padding: 10px 0; */
  padding: 20px 0;
}

.AnimationDay .days .day.first {
  margin-left: 0;
}

.AnimationDay .days .day.select {
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 20%;
  color: black;
  /* padding rajouté par alex */
  padding: 20px 0;
}

.AnimationDay .days .day strong {
  font-size: 2em;
  padding: 0;
  margin: 0;
  line-height: 1em;
  font-weight: bold;
}

.AnimationDay .animations {
  width: 100%;
  background-color: #FBFBFD;
  border-radius: 12px 12px 0 0;
  margin-top: -20px;
  padding-top: 50px;
  min-height: 85vh;
}

.AnimationDay .loading {
  padding-top: 27vh;
}

.AnimationDay .animations .elem {
  width: 90%;
  margin-left: 5%;
  border-left: 2px solid #ababab;
  padding-left: 5%;
  padding-bottom: 20px;
}

.AnimationDay .animations .dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #ababab;
  float: left;
  margin-left: -27px;
}

.AnimationDay .animations .details {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 7vh;
  font-size: 0.8em;
  padding: 20px 20px;
}

.AnimationDay .animations .details .title {
  font-weight: bold;
  font-size: 1.1em;
}

/* .AnimationDay .animations .details p {
	padding: 0;
	margin: 0;
} */

.AnimationDay .animations .details .slots {
  margin-top: 10px;
}

.AnimationDay .animations .details .book {
  text-align: right;
  font-weight: bold;
  margin-top: 10px;
  font-size: 1.05em;
  cursor: pointer;
}

.AnimationDay .animations .details .full {
  text-align: right;
  font-style: italic;
  margin-top: 10px;
  font-size: 1.05em;
}

.AnimationDay .animations .indicators {
  width: 80%;
  margin: auto;
  margin-top: 40px;
  padding-bottom: 40px;
}

/* purgecss end ignore */
/* purgecss start ignore */

.contentWrapper .cartForm {
  width: 100%;
}

.service {
  background-color: white;
  border-radius: 12px;
  padding: 15px 25px;
  width: calc(100% - 40px);
  margin: 20px;
  font-size: 0.85em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.service .picto {
  width: 28px;
  float: left;
  margin-right: 20px;
}

.service .title {
  font-size: 1em;
  margin-bottom: 30px;
}

.service .duration, .service .location, .service .price {
  font-weight: bold;
  margin: 0;
}

.service .quantity
{
  font-weight: bold;
  font-size: small;
  margin: 0;
}

.service .quantity input {
  width: 100px;
  height: 30px;
}

.service .book {
  text-align: right;
  font-weight: bold;
  cursor: pointer;
  /* font-size: 1em; */
}

/* Dates */

.Dates {
  width: 100%;
}

.Dates .header {
  color: white;
  width: 100%;
  background-color: #dedede;
  text-align: left;
  min-height: 140px;
  padding-bottom: 5px;
}

.Dates .header h1 {
  margin: 0;
  font-size: 1.8em;
  margin-left: 25%;
  padding-top: 30px;
}

.Dates .days {
  width: 80%;
  margin: auto;
}

.Dates .days .day {
  color: white;
  margin-top: 20px;
  float: left;
  width: 15%;
  margin-left: 5%;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  padding: 10px 0;
  cursor: pointer;
  padding: 20px 0;
}

.Dates .days .day.first {
  margin-left: 0;
}

.Dates .days .day.select {
  border-radius: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 20%;
  color: black;
  cursor: default;
}

.Dates .days .day strong {
  font-size: 2em;
  padding: 0;
  margin: 0;
  line-height: 1em;
  font-weight: bold;
}

.Dates .slots {
  width: 100%;
  background-color: #FBFBFD;
  border-radius: 12px 12px 0 0;
  margin-top: -20px;
  padding-top: 50px;
  min-height: 85vh;
}

.Dates .loading {
  padding-top: 27vh;
}

.Dates .slots .unavailable {
  font-size: 0.9em;
  font-style: italic;
  text-align: center;
}

.Dates .slots .elem {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  padding: 20px 30px;
}

.Dates .slots .elem .title {
  width: 45%;
  float: left;
}

.Dates .slots .elem .full {
  float: right;
  width: 55%;
  font-weight: bold;
}

.Dates .slots .elem .few {
  float: right;
  width: 55%;
  margin-top: 3px;
  font-size: 0.8em;
  font-style: bold;
}

.contentWrapper.serviceWrapper {
  top: 15vh;
  padding-top: 40px;
}

.contentWrapper.serviceWrapper .question {
  font-size: 1.3em;
  color: #3C3C3C;
}

.contentWrapper.serviceWrapper .mandatory {
  font-style: italic;
}

.contentWrapper.serviceWrapper > form > label > input {
  width: 100%;
  float: none;
  background-color: white;
}

.contentWrapper.serviceWrapper > form > label > input.birthday {
  width: 25%;
  margin-right: 5%;
  float: left;
  background-color: white;
}

.contentWrapper.serviceWrapper > form > label > input.birthday.year {
  width: 40%;
  margin-right: 0;
  float: left;
  background-color: white;
}

.contentWrapper.serviceWrapper button {
  margin-top: 20px;
}

/* purgecss end ignore */
/*
purgecss start ignore
*/

/*
.Home .header {
	position: fixed;
	top: 0;
	z-index: 0;
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	height: 140px;
}

.Home .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 40px;
	padding-top: 35px;
}

.Home .body {
	position: absolute;
	z-index: 200;
	width: 100%;
	background-color: #FBFBFD;
	border-radius: 12px 12px 0 0;
	margin-top: 120px;
	padding-top: 10px;
	min-height: 85vh;
}

.Home .body a {
	text-decoration: none;
}


.Home .body .HomeTitle {
	margin-top: 40px;
	margin-bottom: 20px;
	font-size: 1.2em;
	font-weight: bold;
	padding-left: 20px;
}

.ModuleLayout, .ModuleGray {
	width: 90%;
	margin: auto;
	margin-bottom: 30px;
	border-radius: 10px;
	text-transform: uppercase;
	text-align: right;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 40%;
	font-weight: bold;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	padding-top: 23vh;
}

.ModuleGray {
	background-color: #ABABAB;
}

.ModuleLayout p, .ModuleGray p {
	text-align: center;
	color: white;
	margin: 0;
	width: 100%;
	border-radius: 0 0 10px 10px;
	background-color: rgba(0, 0, 0, 0.3);
}

.ModulePromote {
	width: 90%;
	margin: auto;
	margin-bottom: 30px;
	margin-top: 30px;
	border-radius: 10px;
	background-color: #D6EFFF;
	padding: 10px 20px 20px 20px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.ModulePromote .dl-title {
	font-size: 1em;
	font-weight: 600;
	margin: 15px 0px;
}

.ModulePromote .dl-content {
	font-size: 0.8em;
}

.ModulePromote .btn-container {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.ModulePromote .btn-container .btn-nothanks {
	padding: 10px 15px;
}

.ModulePromote .btn-container .btn-download {
	border-radius: 9999px;
	background-color: #FFFFFF;
	border-style: solid;
	border-width: 1px;
	padding: 10px 15px;
	font-weight: bold;
}
*/

/*
purgecss end ignore
*/
/* purgecss start ignore */

.BottomBar {
  z-index: 30;
  height: 10vh;
  background-color: #F6F8FA;
  width: 100%;
  top: 90vh;
  top: calc(1vh * 90);
  top: calc(var(--vh, 1vh) * 90);
  position: fixed;
  border-top: 1px solid #EAEAEA;
}

.BottomBar .Button {
  float: left;
  width: 25%;
}

.BottomBar .Button .dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: red;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 62%;
  top: 12px;
}

.BottomBar .Button img {
  display: block;
  width: 30%;
  margin: auto;
  margin-top: 15%;
}

.BottomBar .Button p {
  text-align: center;
  margin: 0;
  margin-top: 5px;
  font-size: 0.8em;
  color: gray;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* @tailwind base; */

html 
{
  font-size: 1rem;
}

body 
{
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Montserrat, sans-serif;
}

/* p 
{
	margin-bottom: 1.35rem;
	margin-top: 0.875rem;
} */

/* 
h1 
{
	font-size: 1.5rem;
	line-height: 2rem;
} */

/* 
h2 
{
	font-size: 1.25rem;
	line-height: 1.75rem;
	padding-left: 1.25rem;
	margin-bottom:  1.25rem;
	margin-top: 0.875rem;
} */

/* LOADER */

.lds-ring
{
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div
{
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #E2E8F0;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #E2E8F0 transparent transparent transparent;
}

.lds-ring div:nth-child(1)
{
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2)
{
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3)
{
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring
{
  0%
	{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
	{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lds-ring
{
  0%
	{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
	{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */
