.Reviews .body {
	padding: 0 15%;
	text-align: left;
	background-color: white;
	padding-bottom: 100px;
}

.Reviews .body h1 {
	font-size: 1.6em;
	font-weight: bold;
	text-align: left;
	margin-bottom: 0;
	margin-top: 75px;
}

.Reviews .body h1.thanks {
	font-size: 1.8em;
	margin: 0 5%;
	margin-top: 50px;
}

.Reviews .body .introduction {
	text-align: center;
	font-weight: bold;
	font-size: 1.3em;
	margin-top: 80px;
}

.Reviews .illustration {
	margin-top: 50px;
	text-align: center;
}

.Reviews .illustration img {
	width: 50%;
}

.Reviews .body h2 {
	color: #A5A5A5;
	font-weight: bold;
	margin-top: 15px;
	font-size: 0.85em;
}

.Reviews .text {
	margin-top: 100px;
}

.Reviews .notation {
	margin-top: 100px;
	text-align: center;
}

.Reviews .notation img {
	width: 10%;
	margin: 0 5px;
	display: inline-block;
}

.Reviews .select {
	width: 100%;
	font-size: 1.1em;
	background-color: white;
	border-radius: 10px;
	padding: 20px 30px;
	margin: 20px auto;
	box-shadow: 0px 3px 6px #00000029;
	font-weight: bold;
	color: #909090;
}

.Reviews .select.selected {
	background-color: #EDF2FA;
}

.Reviews .error {
	color: #909090;
	margin: auto;
}

.Reviews .error .icon {
	margin-left: 50px;
	float: left;
	border-radius: 100%;
	background-color: #FF6464;
	color: white;
	width: 24px;
	height: 24px;
	font-size: 20px;
	padding: 2px;
	margin-top: 16px;
}

.Reviews .error p {
	float: left;
	font-weight: bold;
	margin-left: 10px;
	margin-top: 15px;
}

.Reviews .buttons {
	text-align: center;
	width: 90%;
	position: fixed;
	top: 75vh;
	left: 5%;
}

.Reviews button {
	font-size: 1em;
	background-color: #cacaca;
	color: white;
	border: none;
	border-radius: 30px;
	padding: 20px 0;
}

.Reviews button.back {
	float: left;
	width: 20%;
	margin: 0;
	margin-left: 10%;
	padding: 20px;
	border-radius: 20px;
	background-color: white;
	border: 1px solid #909090;
}

.Reviews button.back img {
	width: 40%;
}

.Reviews button.continue {
	float: right;
	width: 50%;
	margin: 0;
	margin-right: 10%;
}

.Reviews button.alone-btn {
	width: 70%;
	margin: auto;
}