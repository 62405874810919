.askNotification
{
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin: 50px auto 50px auto;
	width: calc(100% - 30px);
	padding: 15px;
	background-color: white;
}

.askNotification .promote {
	margin-left: 15%;
	color: black;
	font-size: 0.8em;
}

.askNotification .promote strong {
	font-size: 1.3em;
}

.custom-checkbox[type="checkbox"]:not(:checked), 
.custom-checkbox[type="checkbox"]:checked {
	/* Cache la checkbox sans
	   la désactiver pour les
	   lecteurs d'écran */
	position: absolute;
	left: 0;
	opacity: 0.01;
}

/* on prépare le label */
.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label {
	float: left;
	position: relative; /* permet de positionner les pseudo-éléments */
	padding-left: 2.3em; /* fait un peu d'espace pour notre case à venir */
	font-size: 1.05em; /* Label un peu plus gros */
	line-height: 1.7;
	cursor: pointer;    /* affiche un curseur adapté */
}

/* Aspect de la case */
.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:before,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 1.4em;
	height: 1.4em;
	border: 1px solid #888;
	background-color: #FAFAFA;
	border-radius: .2em;
	box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
	transition: all .275s;
}

/* Aspect de la coche */
.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:after,
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:after {
	content: '✓';
	position: absolute;
	top: .5em;
	left: .1em;
	font-size: 1.6em;
	color: #000;
	line-height: 0;
	transition: all .2s; /* Petite transition */
}

/* Aspect non cochée */
.custom-checkbox[type="checkbox"]:not(:checked) + .custom-checkbox-label:after {
	opacity: 0;
	transform: scale(0) rotate(45deg);
}

/* Aspect cochée */
.custom-checkbox[type="checkbox"]:checked + .custom-checkbox-label:after {
	opacity: 1;
	transform: scale(1) rotate(0);
}

/* Disabled checkbox */
.custom-checkbox[type="checkbox"]:disabled:not(:checked) + .custom-checkbox-label:before,
.custom-checkbox[type="checkbox"]:disabled:checked + .custom-checkbox-label:before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #e9e9e9;
}

/* Disabled checked */
.custom-checkbox[type="checkbox"]:disabled:checked + .custom-checkbox-label:after {
	color: #777;
}

.custom-checkbox[type="checkbox"]:disabled + .custom-checkbox-label {
	color: #aaa;
}