.BottomBar {
    z-index: 30;
    height: 10vh;
    background-color: #F6F8FA;
    width: 100%;
    top: 90vh;
    top: calc(var(--vh, 1vh) * 90);
    position: fixed;
    border-top: 1px solid #EAEAEA;
}

.BottomBar .Button {
    float: left;
    width: 25%;
}

.BottomBar .Button .dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: red;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 62%;
    top: 12px;
}

.BottomBar .Button img {
    display: block;
    width: 30%;
    margin: auto;
    margin-top: 15%;
}

.BottomBar .Button p {
    text-align: center;
    margin: 0;
    margin-top: 5px;
    font-size: 0.8em;
    color: gray;
}
