/* @tailwind base; */

html 
{
	font-size: 1rem;
}

body 
{
	font-family: -apple-system, BlinkMacSystemFont, Roboto, Montserrat, sans-serif;
}

/* p 
{
	margin-bottom: 1.35rem;
	margin-top: 0.875rem;
} */
/* 
h1 
{
	font-size: 1.5rem;
	line-height: 2rem;
} */
/* 
h2 
{
	font-size: 1.25rem;
	line-height: 1.75rem;
	padding-left: 1.25rem;
	margin-bottom:  1.25rem;
	margin-top: 0.875rem;
} */

/* LOADER */

.lds-ring
{
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ring div
{
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #E2E8F0;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #E2E8F0 transparent transparent transparent;
}

.lds-ring div:nth-child(1)
{
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2)
{
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3)
{
	animation-delay: -0.15s;
}

@keyframes lds-ring
{
	0%
	{
		transform: rotate(0deg);
	}
	100%
	{
		transform: rotate(360deg);
	}
}