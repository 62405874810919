.Notifications {
	min-height: 80vh;
}

.Notifications .header {
	position: fixed;
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	height: 140px;
}

.Notifications .header h1 {
	margin: 0;
	font-size: 1.8em;
	padding-top: 25px;
	text-align: left;
	margin: 0 50px 0 25%;
}

.Notifications .body {
	position: absolute;
	width: 100%;
	background-color: white;
	margin-top: 120px;
	min-height: 90vh;
}

.notification {
	width: 100%;
	min-height: 7vh;
	font-size: 0.7em;
	padding: 10px 40px;
	border-bottom: 1px solid #DADADA;
}

.notification.unread {
	background-color: #EDF2FA;
}

.notification .title {
	font-weight: bold;
	font-size: 1.1em;
	padding: 0;
	margin: 0;
}

.notification .text {
	padding: 0;
	margin: 0;
}

.notification .time {
	color: #6a6a6a;
	padding: 0;
	margin: 0;
}