.contentWrapper .cartForm {
	width: 100%;
}

.service {
	background-color: white;
	border-radius: 12px;
	padding: 15px 25px;
	width: calc(100% - 40px);
	margin: 20px;
	font-size: 0.85em;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.service .picto {
	width: 28px;
	float: left;
	margin-right: 20px;
}

.service .title {
	font-size: 1em;
	margin-bottom: 30px;
}

.service .duration, .service .location, .service .price {
	font-weight: bold;
	margin: 0;
}

.service .quantity
{
	font-weight: bold;
	font-size: small;
	margin: 0;
}

.service .quantity input {
	width: 100px;
	height: 30px;
}

.service .book {
	text-align: right;
	font-weight: bold;
	cursor: pointer;
	/* font-size: 1em; */
}

/* Dates */

.Dates {
	width: 100%;
}

.Dates .header {
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	min-height: 140px;
	padding-bottom: 5px;
}

.Dates .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 25%;
	padding-top: 30px;
}

.Dates .days {
	width: 80%;
	margin: auto;
}

.Dates .days .day {
	color: white;
	margin-top: 20px;
	float: left;
	width: 15%;
	margin-left: 5%;
	text-align: center;
	font-size: 0.8em;
	font-weight: bold;
	padding: 10px 0;
	cursor: pointer;
	padding: 20px 0;
}

.Dates .days .day.first {
	margin-left: 0;
}

.Dates .days .day.select {
	border-radius: 15px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	background-color: white;
	width: 20%;
	color: black;
	cursor: default;
}

.Dates .days .day strong {
	font-size: 2em;
	padding: 0;
	margin: 0;
	line-height: 1em;
	font-weight: bold;
}

.Dates .slots {
	width: 100%;
	background-color: #FBFBFD;
	border-radius: 12px 12px 0 0;
	margin-top: -20px;
	padding-top: 50px;
	min-height: 85vh;
}

.Dates .loading {
	padding-top: 27vh;
}

.Dates .slots .unavailable {
	font-size: 0.9em;
	font-style: italic;
	text-align: center;
}

.Dates .slots .elem {
	background-color: white;
	border-radius: 12px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	width: 90%;
	margin: auto;
	margin-bottom: 20px;
	font-weight: bold;
	padding: 10px 20px;
	cursor: pointer;
	padding: 20px 30px;
}

.Dates .slots .elem .title {
	width: 45%;
	float: left;
}

.Dates .slots .elem .full {
	float: right;
	width: 55%;
	font-weight: bold;
}

.Dates .slots .elem .few {
	float: right;
	width: 55%;
	margin-top: 3px;
	font-size: 0.8em;
	font-style: bold;
}

.contentWrapper.serviceWrapper {
	top: 15vh;
	padding-top: 40px;
}

.contentWrapper.serviceWrapper .question {
	font-size: 1.3em;
	color: #3C3C3C;
}


.contentWrapper.serviceWrapper .mandatory {
	font-style: italic;
}

.contentWrapper.serviceWrapper > form > label > input {
	width: 100%;
	float: none;
	background-color: white;
}

.contentWrapper.serviceWrapper > form > label > input.birthday {
	width: 25%;
	margin-right: 5%;
	float: left;
	background-color: white;
}

.contentWrapper.serviceWrapper > form > label > input.birthday.year {
	width: 40%;
	margin-right: 0;
	float: left;
	background-color: white;
}

.contentWrapper.serviceWrapper button {
	margin-top: 20px;
}