.contentWrapper {
	min-height: 80vh;
	width: 100%;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 25vh;
	background-color: #FBFBFD;
	border-radius: 1.5rem 1.5rem 0 0;
}

.contentWrapper .link {
	width: 100%;
	border: 1px solid #f6b25f;
	border-radius: 10px;
	color: white;
	background-color: #f6b25f;
	height: 40px;
	font-size: 1.2em;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.contentWrapper .fullPage {
	margin: 0;
	padding: 0;
	width: 100%;
	margin-top: -12vh;
}

/* .contentWrapper .page {
	background-color: white;
	border-radius: 12px;
	padding: 15px 25px 0 25px;
	width: calc(100% - 40px);
	margin: 20px;
	font-size: 0.85em;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	min-height: 80px;
} */

.contentWrapper p {
	margin-bottom: 15px;
}

.contentWrapper .page b, .contentWrapper .page strong {
	font-weight: bolder;
}

.contentWrapper ul {
	padding-left: 40px !important;
}

.contentWrapper ul li {
	list-style-type: disc !important;
}

.contentWrapper .page a {
	color: black;
	text-decoration: none;
}

.contentWrapper .page .reveal-on-tap {
	max-height: 0;
	overflow-y: hidden;
	padding-bottom: 0px;
	transition: max-height 0.8s ease, padding-bottom 0.8s ease;
}

.photoWrap {
	max-height: 30vh;
	overflow-y: hidden;
	margin-bottom: 5%;
	position: fixed;
}

.photoWrap img {
	width: 100%;
}

/* .contentWrapper .picto {
	width: 28px;
	margin-top: 12px;
	float: left;
} */

/* .contentWrapper .title {
	padding-left: 42px;
	font-weight: bold;
	font-size: 1.15em;
	margin-bottom: 30px;
	padding-top: 14px;
} */

.contentWrapper form {
	width: 70%;
	margin: auto;
}

.contentWrapper form .row {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	margin-bottom: 5%;
}

.contentWrapper form label {
	clear: both;
	text-transform: uppercase;
	color: #afafaf;
	font-weight: bold;
}

.contentWrapper form input[type="text"] {
	width: 50%;
	float: right;
	border: 1px solid #e4e9e9;
	height: 30px;
	border-radius: 5px;
}

.contentWrapper form select {
	width: 30%;
	height: 30px;
	border-radius: 5px;
	float: right;
	background-color: white;
	border: 1px solid #e4e9e9;
}

.contentWrapper form button {
	width: 100%;
	border: 1px solid #f6b25f;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	background-color: #f6b25f;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	margin-top: 20%;
	padding-top: 5%;
	padding-bottom: 5%;
}

.contentWrapper .check {
	padding-top: 20vh;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.2em;
}

.contentWrapper .check img {
	width: 50%;
	padding-bottom: 15%;
}

/* .content {
	width: 90%;
	min-height: 105px;
	margin: 20px auto;
	color: white;
	border-radius: 10px;
	background-color: #c6c6c6;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.content img {
	width: 15%;
	float: left;
	margin-left: 10%;
	margin-top: 7%;
} */

/* .content p {
	margin-left: 35%;
	font-size: 1.05em;
	font-weight: bold;
	text-transform: uppercase;
	padding: 20px 10px 20px 0;
} */

.contentWrapper a {
	text-decoration: none;
}

.content.first {
	background-color: #fbdfb6;
}

.content.second {
	background-color: #f9c981;
}

.content.third {
	background-color: #f6b95c;
}

.content.fourth {
	background-color: #f5a938;
}

.content.fifth {
	background-color: #f3960c;
}