/* AnimationMonth */

.AnimationMonth {
	width: 100%;
	background-color: #FBFBFD;
	min-height: 90vh;
	padding-bottom: 20px;
}

.AnimationMonth .header {
	position: fixed;
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	height: 140px;
}

/* .AnimationMonth .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 25%;
	padding-top: 25px;
} */

.AnimationMonth .body {
	position: absolute;
	width: 100%;
	background-color: #FBFBFD;
	border-radius: 12px 12px 0 0;
	margin-top: 120px;
	min-height: 90vh;
}

.AnimationMonth .month {
	text-transform: uppercase;
	font-size: 1em;
	width: 80%;
	margin: auto;
	margin-top: 20px;
}

.AnimationMonth .month img {
	width: 20px;
	margin-top: 5px;
}

.AnimationMonth .month p {
	margin-top: 40px;
	float: left;
	font-size: 1em;
	font-weight: bold;
}

.AnimationMonth .month .arrow-left, .AnimationMonth .month .arrow-right {
	width: 20%;
}

.AnimationMonth .month .arrow-right {
	float: right;
	text-align: right;
}

.AnimationMonth .month .text {
	text-align: center;
	width: 60%;
	margin-bottom: 20px;
}

.AnimationMonth .calendar {
	clear: both;
	background-color: white;
	color: black;
	border-radius: 10px;
	width: calc(100% - 30px);
	margin: auto;
	padding: 10px 10px 30px 10px;
}

.AnimationMonth .weekDays {
	width: 100%;
	margin: auto;
	color: #C3C3C3;
	border-bottom: 1px solid #E3E3E3;
}

.AnimationMonth .weekDays .name {
	display: inline-block;
	font-size: 0.8em;
	font-weight: bold;
	text-align: center;
	width: 14.28571%;
}

.AnimationMonth .monthTable {
	width: 100%;
	margin-top: 20px;
}

.AnimationMonth .monthTable .dayRow {
	width: 100%;
}

.AnimationMonth .monthTable .dayRow .day {
	vertical-align: top;
	display: inline-block;
	width: 11%;
	margin-left: 3.83333%;
	height: 5.5vh;
}

.AnimationMonth .monthTable .dayRow .day.highlight {
	/* background-color: #FFB400; */
	background-color: #DADADA;
	border-radius: 10px;
}

.AnimationMonth .monthTable .dayRow .day.first {
	margin-left: 0;
}

.AnimationMonth .monthTable .dayRow .day .num {
	/*color: #3c3c3c;*/
	color: #676565;
	padding: 0;
	margin: 0;
	padding-top: 12px;
	/* margin-top: 2%; */
	/* margin-right: 10%; */
	/* font-weight: bold; */
	text-align: center;
	font-size: 0.9em;
}

.AnimationMonth .monthTable .dayRow .day .num.gray {
	color: #C3C3C3;
}

.AnimationMonth .monthTable .dayRow .day.highlight .num {
	color: #676565;
}

.AnimationMonth .monthTable .dayRow .day .pastille {
	height: 5px;
	width: 5px;
	background-color: black;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	margin-top: 35%;
	margin-left: 7%;
	display: inline-block;
}

.AnimationMonth .monthTable .dayRow .day .pastille.first {
	background-color: #f29100;
}

.AnimationMonth .monthTable .dayRow .day .pastille.second {
	background-color: #71beb9;
}

.AnimationMonth .monthTable .dayRow .day .pastille.third {
	background-color: #9c9c9b;
}

.AnimationMonth .indicators {
	margin-top: 15%;
	margin-left: 5%;
	font-size: 0.9em;
}

.AnimationMonth .indicators .pastille, .AnimationDay .indicators .pastille {
	height: 13px;
	width: 13px;
	background-color: black;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	margin-right: 5%;
	display: inline-block;
}

/* AnimationDay */

.AnimationDay {
	width: 100%;
}

.AnimationDay .header {
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	min-height: 140px;
	padding-bottom: 5px;
}

/* .AnimationDay .header h1 {
	margin: 0;
	font-size: 1.8em;
	margin-left: 25%;
	padding-top: 30px;
} */

.AnimationDay .days {
	width: 80%;
	margin: auto;
}

.AnimationDay .days .day {
	color: white;
	margin-top: 20px;
	float: left;
	width: 15%;
	margin-left: 5%;
	text-align: center;
	font-size: 0.8em;
	font-weight: bold;
	/* padding: 10px 0; */
	padding: 20px 0;
}

.AnimationDay .days .day.first {
	margin-left: 0;
}

.AnimationDay .days .day.select {
	border-radius: 15px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	background-color: white;
	width: 20%;
	color: black;
	/* padding rajouté par alex */
	padding: 20px 0;
}

.AnimationDay .days .day strong {
	font-size: 2em;
	padding: 0;
	margin: 0;
	line-height: 1em;
	font-weight: bold;
}

.AnimationDay .animations {
	width: 100%;
	background-color: #FBFBFD;
	border-radius: 12px 12px 0 0;
	margin-top: -20px;
	padding-top: 50px;
	min-height: 85vh;
}

.AnimationDay .loading {
	padding-top: 27vh;
}

.AnimationDay .animations .elem {
	width: 90%;
	margin-left: 5%;
	border-left: 2px solid #ababab;
	padding-left: 5%;
	padding-bottom: 20px;
}

.AnimationDay .animations .dot {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background-color: #ababab;
	float: left;
	margin-left: -27px;
}

.AnimationDay .animations .details {
	background-color: white;
	border-radius: 12px;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	width: 100%;
	min-height: 7vh;
	font-size: 0.8em;
	padding: 20px 20px;
}

.AnimationDay .animations .details .title {
	font-weight: bold;
	font-size: 1.1em;
}

/* .AnimationDay .animations .details p {
	padding: 0;
	margin: 0;
} */

.AnimationDay .animations .details .slots {
	margin-top: 10px;
}

.AnimationDay .animations .details .book {
	text-align: right;
	font-weight: bold;
	margin-top: 10px;
	font-size: 1.05em;
	cursor: pointer;
}

.AnimationDay .animations .details .full {
	text-align: right;
	font-style: italic;
	margin-top: 10px;
	font-size: 1.05em;
}

.AnimationDay .animations .indicators {
	width: 80%;
	margin: auto;
	margin-top: 40px;
	padding-bottom: 40px;
}