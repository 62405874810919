/* Signin */

.Signin {
	height: 100vh;
	margin: 0;
	background-color: white;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.Signin .logo {
	display: block;
	margin: auto;
	width: 40%;
	margin-top: 50px;
}

.Signin h1 {
	text-align: center;
	margin-bottom: 50px;
	font-size: 3em;
}

.Signin h1.collapse {
	margin: 0;
	margin-bottom: 50px;
}

.Signin h2 {
	text-align: center;
	margin-bottom: 6vh;
	font-size: 2.5em;
}

.Signin h3 {
	font-weight: normal;
	font-size: 1em;
	color: red;
	text-align: center;
}

.Signin p {
	text-align: center;
}

.Signin .wait {
	text-align: center;
	margin: 135px 0;
}

.Signin .promote {
	font-size: 1.1em;
	width: 90%;
	margin: auto;
}

.Signin .info {
	font-size: 0.8em;
	color: #888;
}

.Signin .download {
	width: 60%;
	margin: auto;
	display: block;
	margin-top: 60px;
	font-size: 1.3em;
	font-weight: bolder;
}

.Signin .nothanks {
	width: 60%;
	margin: auto;
	display: block;
	text-align: center;
	font-size: 1.3em;
	margin-top: 30px;
	font-weight: bolder;
	background-color: transparent;
}

.Signin form {
	width: 80%;
	margin: auto;
	color: #898989;
}

.Signin form .row {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	text-align: right;
}

.Signin form .row.send {
	text-align: center;
}

.Signin form .row textarea {
	width: 100%;
	height: 25vh;
}

.Signin form .row input[type="text"], .Signin form .row input[type="password"] {
	margin-right: 15px;
	height: 40px;
	padding: 5px 15px;
	font-size: 1.2em;
	width: calc(100% - 30px);
	border: none;
	color: #898989;
	background-color: #F4F4F4;
	border-radius: 5px;
}

.Signin form .row input[type="text"]:focus, .Signin form .row input[type="password"]:focus, .Signin form .row select:focus {
	outline: none;
}

.Signin form .visible {
	width: 100%;
	text-align: right;
	/* margin-top: -5px; */
}

.Signin form .visible p {
	margin-top: 2px;
	float: left;
	margin-left: 40%;
}

.Signin form .visible label {
	float: right;
}

.Signin form .row .birthdate {
	float: right;
	margin-left: 3%;
	width: 30%;
}

.Signin form .row .radio {
	float: left;
	margin-bottom: 12px;
	font-size: 1.3em;
	width: 30%;
	margin-right: 3%;
}

.Signin button {
	text-align: center;
	margin-top: 30px;
	height: 40px;
	font-size: 1em;
	width: 100%;
	background-color: gray;
	color: white;
	border: none;
	border-radius: 20px;
}

.Signin form .step1-btn {
	padding: 0;
	width: 100%;
	font-size: 1.2em;
	height: 50px;
	margin-top: 0;
}

.Signin form button:hover {
	cursor: pointer;
}

.Signin form .label {
	float: right;
	width: 100%;
	text-align: left;
	font-size: 0.8em;
	margin-bottom: 10px;
}

.Signin form .row select {
	background-color: white;
	float: right;
	margin-bottom: 20px;
	height: 50px;
	padding: 0 5px;
	font-size: 1.2em;
	width: 100%;
	color: #898989;
	background-color: #F4F4F4;
	border: none;
	border-radius: 5px;
}


.Signin a {
	color: #898989;
	text-decoration: none;
}

.Signin a u {
	text-decoration: underline;
}

.Signin .center {
	text-align: center;
}

.Signin .box-content {
	width: 80%;
	margin: auto;
	margin-bottom: 50px;
}

.Signin p {
	color: #898989;
	text-align: left;
}

.Signin .underline {
	text-decoration: underline;
}

.Signin .success {
	color: green;
	text-align: center;
}

.Signin form .row .stays {
	font-size: 1em;
}

.Signin .stays option {
	font-size: 0.9em;
}

.Signin .switchPage {
	text-align: center;
	margin-top: 30px;
	font-size: 1.1em;
}