@font-face {
	font-family: Nunito;
	src: url('../../fonts/Nunito/Nunito-Regular.ttf');
}

@font-face {
	font-family: Nunito;
	src: url('../../fonts/Nunito/Nunito-Light.ttf');
	font-weight: lighter;
}

@font-face {
	font-family: Nunito;
	src: url('../../fonts/Nunito/Nunito-SemiBold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: Nunito;
	src: url('../../fonts/Nunito/Nunito-ExtraLightItalic.ttf');
	font-style: italic;
}

html, body {
	-webkit-overflow-scrolling: touch;
}

.App {
	margin: 0;
	padding: 0;
	border: 0;
	color: #3c3c3c;
	font-family: -apple-system, BlinkMacSystemFont, Roboto, Montserrat, sans-serif;
}
/* font-family: Roboto, Montserrat, Arial, serif; */

.App .loading {
	margin: auto;
	margin-top: 45vh;
	text-align: center;
	width: 100%;
}

.Wrapper {
	scroll-behavior: smooth;
	overflow-x: hidden;
	font-size: 1.2em;
	z-index: 20;
	position: absolute;
	top: 0;
	height: calc(var(--vh, 1vh) * 90);
	width: 100%;
	/* z-index: -100; */
	-webkit-overflow-scrolling: touch;
}

.wrapper-content {
	min-height: 80vh;
}

.wrapper-content .header {
	position: fixed;
	color: white;
	width: 100%;
	background-color: #dedede;
	text-align: left;
	height: 140px;
}

.wrapper-content .header h1 {
	margin: 0;
	font-size: 1.8em;
	padding-top: 25px;
	text-align: left;
	margin: 0 50px 0 25%;
}

.wrapper-content .body {
	position: absolute;
	width: 100%;
	background-color: #FBFBFD;
	border-radius: 12px 12px 0 0;
	margin-top: 120px;
	min-height: 90vh;
	padding: 0 20px;
	padding-top: 10px;
}

.wrapper-content h1 {
	font-size: 1.5em;
	text-align: center;
}

.wrapper-content h2 {
	font-size: 1.3em;
}

.wrapper-content button, .contentWrapper button {
	width: 100%;
	border: 1px solid #f6b25f;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	background-color: #f6b25f;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	padding-top: 5%;
	padding-bottom: 5%;
	position: relative;
	cursor: pointer;
	margin: 5vh 0;
}

.contentWrapper button {
	width: 90%;
	margin: 5vh auto;
	display: block;
}

.success {
	text-align: center;
	color: green;
}

.error {
	text-align: center;
	color: red;
}

.center {
	text-align: center;
	margin: 20px 0;
}
