.Slider {
	width: 100%;
	margin-bottom: 30px;
	white-space: nowrap;
}

.listElems {
	width: 90%;
	margin: auto;
	/* overflow-x: hidden; */
}

.Slider .element {
	display: inline-block;
	width: 120px;
	margin-right: 10px;
	vertical-align: top;
}

.Slider .element div {
	height: 147px;
	padding-top: 3vh;
	background-color: #c6c6c6;
	border-radius: 12px;
	text-align: center;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	white-space: normal;
}

/* .Slider .element p {
	white-space: normal;
	overflow-wrap: break-all;
	padding: 0;
	margin: 0;
	margin-top: 10px;
	text-align: center;
	color: white;
	font-family: Helvetica, Nunito, Arial, sans-serif; */
/* } */

/* .Slider .element img {
	width: 40%;
	display: inline;
} */