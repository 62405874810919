/*.Header {
	z-index: 50;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 10vh;
	background-color: #dedede;
	margin: 0;
	padding: 0;
	padding-left: 2%;
	display: flex;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.Header-burger {
	width: 10%;
}

.Header-burger img {
	height: 100px;
}

.Header {
	z-index: 20;
	display: block;
	top: 10px;
	position: fixed;
	width: 100%;
}

.Header a, .Header button {
	z-index: 20;
	margin-left: 10px;
}

.Header-title {
	margin: 0;
	padding: 0;
	width: 80%;
}

.Header-title h1 {
	color: white;
	padding-top: 2.5vh;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
}

.Header-title h1 a {
	color: white;
	text-decoration: none;
}
*/
.Menu {
	/* z-index: 10; */
	display: none;
	top: 0;
	position: fixed;
	margin-left: -80%;
	border-right: 1px solid #c6c6c6;
	width: 80%;
	height: 90vh;
	background-color: white;
	animation: 0.5s slideIn;
}

.Menu ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	text-transform: uppercase;
}

.Menu li {
	height: 6vh;
	width: 60%;
	padding-left: 20%;
	margin-left: 10%;
	background-size: 15%;
	background-repeat: no-repeat;
	background-position: 5% 50%;
}

.Menu .elem {
	margin-top: 10px;
	width: 100%;
}

.Menu li a {
	color: #3c3c3c;
	text-decoration: none;
}

.Menu .Menu-title {
	padding-top: 3.2vh;
	padding-left: 0;
	margin-left: 0;
	height: 19vh;
	width: 100%;
	text-align: center;
	border-top: none;
	font-size: 1.3em;
}

.Menu .Menu-title img {
	width: 40%;
	margin: auto;
}

.Menu li .icon {
	width: 36px;
	float: left;
	margin-left: -50px;
	margin-top: -10px;
}

@keyframes slideIn {
	from {
		margin-left: -80%;
	}

	to {
		margin-left: 0;
	}
}

@keyframes slideOut {
	from {
		margin-left: 0;
	}

	to {
		margin-left: -80%;
	}
}

/* RESPONSIVE SMARTPHONE */

@media (max-width: 600px) {
	.Header-burger img {
		height: 5vh;
		padding-top: 2.3vh;
		padding-left: 30%;
	}

	.GhostHeader .Header-burger img {
		padding-left: 20px;
	}

	.Header-title h1 {
		font-size: 1.5em;
	}
}